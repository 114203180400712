import { Injectable } from '@angular/core';
import { Game } from '../domain/game';
import {
  CollectionOption,
  CollectionOptionId
} from './game-collection-option.model';

const collectionGroups: Record<string, CollectionOptionId[]> = {
  'data options': [
    'is_assists_collection',
    'goal_clips',
    'is_highlight_collection',
    'isExportVideoReviewClips'
  ],
  'collection options': [
    'is_live_collection',
    'lagClock',
    'auto_puck_possession_events',
    'gameTimeImport',
    'is_live_draft_events'
  ],
  'Marker and other options': [
    'videoReview',
    'is_training_game',
    'is_sihf_live',
    'disable_updates'
  ]
};

const collectionOptions: CollectionOption[] = [
  {
    id: 'is_live_collection',
    fieldName: 'isLiveCollection',
    label: 'Live Collection',
    tooltip:
      'Optimizes user interface for live collection, adjusts required validations'
  },
  {
    id: 'is_highlight_collection',
    fieldName: 'isHighlightCollection',
    label: 'Highlight Collection',
    tooltip: 'Enables highlight events and rating column'
  },
  {
    id: 'is_live_draft_events',
    fieldName: 'isLiveDraftEvents',
    label: 'Realtime Collection',
    tooltip:
      'Optimizes FOINT/Shot event types for fast, uninterrupted creation of draft events, requires 2nd-pass'
  },
  {
    id: 'auto_puck_possession_events',
    fieldName: 'autoPuckPossessionEvents',
    label: 'Automated Neutral Puck Possession',
    tooltip:
      'Automatically adds neutral puck possession events directly after face-offs, shots and passes (chip, rim, incomplete)',
    condition: (game: Game) =>
      !game.dataSet || (game.dataSet !== 'LITE' && game.dataSet !== 'LITE_PLUS')
  },
  {
    id: 'goal_clips',
    fieldName: 'goalClips',
    label: 'Goal Clips',
    tooltip: 'Requires enriching goals with slow motion sequences'
  },
  {
    id: 'is_assists_collection',
    fieldName: 'isAssistsCollection',
    label: 'Assists',
    tooltip: 'Requires enriching goals with assists'
  },
  {
    id: 'is_sihf_live',
    fieldName: 'isSihfLive',
    label: 'SIHF Live Export',
    tooltip: 'Exports data to SIHF Game Center in realtime'
  },
  {
    id: 'disable_updates',
    fieldName: 'disableUpdates',
    label: 'Disable Automated SIHF Updates',
    tooltip: 'Prevents SIHF game lineup from being overridden',
    condition: (game: Game) => game.idType === 'SIHF' && !!game.gameId
  },
  {
    id: 'is_training_game',
    fieldName: 'isTrainingGame',
    label: 'Training Game',
    tooltip: 'Data is not exported (Data Cockpit, SIHF, others)'
  },
  {
    id: 'lagClock',
    fieldName: 'lagClock',
    label: 'Show Lag Clock',
    tooltip: 'Shows a clock with difference to live in the video view'
  },
  {
    id: 'videoReview',
    fieldName: 'isVideoReview',
    label: 'Video Review',
    tooltip: 'Enables game in VJ360'
  },
  {
    id: 'gameTimeImport',
    fieldName: 'isGameTimeImport',
    label: 'Import SIHF GameTime',
    tooltip: 'Enables import of SIHF game time as period start/end events',
    condition: (game: Game) => game.idType === 'SIHF' && !!game.gameId
  },
  {
    id: 'isExportVideoReviewClips',
    fieldName: 'isExportVideoReviewClips',
    label: 'Video Review Clips',
    tooltip: 'Requires enriching highlight events of type "Video Review"',
    condition: (game: Game) => game.isHighlightCollection && game.isVideoReview
  }
];

@Injectable({
  providedIn: 'root'
})
export class GameCollectionOptionService {
  constructor() {}

  getAllCollectionOptions() {
    return [...collectionOptions];
  }

  getAllCollectionGroups() {
    return { ...collectionGroups };
  }

  getCollectionGroups(game: Game) {
    const groups = [];
    Object.keys(collectionGroups).forEach((key) => {
      groups[key] = collectionGroups[key].filter((optionId) => {
        const option: CollectionOption = this.findOption(optionId);
        return !option?.condition || option?.condition(game);
      });
    });
    return groups;
  }

  collectionOptions(optionIds: CollectionOptionId[]) {
    return optionIds.map((optionId) => this.findOption(optionId));
  }

  private findOption(optionId: CollectionOptionId) {
    return collectionOptions.find((option) => option.id === optionId);
  }
}
