import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Video } from '../domain/game';
import { GameEvent, VideoClipType } from '../domain/game-event';

export type LiveStreamPlatform =
  | 'antmedia'
  | 'medialive'
  | 'mux'
  | 'ovenmediaengine';

export interface Stream {
  streamId: string;
  camera: string;
  name: string;
  platform: LiveStreamPlatform;
  description?: string;
  type: 'liveStream' | 'playlist';
  status: string;
  publishType: 'RTMP';
  startTime: number;
  protocols: StreamProtocol[];
  muxData?: MuxData;
}

export interface MuxData {
  streamId: string;
  activeAssetId: string; // DVR playback asset
}

export interface StreamProtocol {
  type: 'webrtc' | 'hls';
  url: string;
}

export interface StreamDetails {
  streamId: string;
  startTime: number;
}

export interface ArchiveLiveStreamResult {
  status: string;
  commandId: string;
}

@Injectable()
export class VideoService {
  static readonly ADDITIONAL_TIME: number = 50;
  static readonly DEFAULT_DURATION: number = 35;
  static readonly GOAL_SLOW_SEQUENCE_TIME_START: number = 30;

  private videosUrl = environment.API_HOST + '/api/videos';

  constructor(private http: HttpClient) {}

  listServers(type: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.videosUrl}/servers`, {
      params: { type }
    });
  }

  listStreams(type: string, server: string, app: string): Observable<Stream[]> {
    return this.http.get<Stream[]>(`${this.videosUrl}/streams`, {
      params: {
        type,
        server,
        app
      }
    });
  }

  getMuxAssetDetails(liveStreamId: string, assetId: string): Observable<any> {
    return this.http.get<StreamDetails>(
      `${this.videosUrl}/streams/${liveStreamId}/mux-asset/${assetId}`
    );
  }

  getWebRTCStreamDetails(
    gameId: string,
    videoId: string
  ): Observable<StreamDetails> {
    return this.http.get<StreamDetails>(
      `${this.videosUrl}/${gameId}/${videoId}/stream-details`
    );
  }

  connectStreamToGame(
    gameId: string,
    videoUrl: string,
    camera: string
  ): Observable<StreamDetails> {
    return this.http.post<StreamDetails>(
      `${this.videosUrl}/${gameId}/connect`,
      { videoUrl, camera }
    );
  }

  prepareMediaData(allVideos: Video[]): any[] {
    return allVideos.map((video, index) => {
      if (video.url && video.url.indexOf('.m3u8') > -1) {
        video.mediaId = `video-hls-${index}`;
      } else if (
        video.url &&
        (video.url.startsWith('ws://') || video.url.startsWith('wss://'))
      ) {
        video.mediaId = `video-webrtc-${index}`;
      } else if (video.url && video.url.indexOf('.mpd') > -1) {
        video.mediaId = `video-dash-${index}`;
      } else {
        video.mediaId = `video-vod-${index}`;
      }

      video.index = index;

      return video;
    });
  }

  archiveLiveStream(gameId: string) {
    return this.http.post<ArchiveLiveStreamResult>(
      `${this.videosUrl}/${gameId}/archive-live-stream`,
      {}
    );
  }

  manageMediaServer(gameId: string, action: string) {
    return this.http.post<ArchiveLiveStreamResult>(
      `${this.videosUrl}/${gameId}/manage-media-server`,
      { action }
    );
  }

  /**
   * If durationVideoClip is not provided we are trying to achieve DEFAULT_DURATION or below
   * if durationOfFullVideo is bellow DEFAULT_DURATION.
   *
   * In our default case we are trying to achieve ADDITIONAL_TIME before start
   * and ADDITIONAL_TIME after end of our desired range.
   * ADDITIONAL_TIME | DEFAULT_DURATION | ADDITIONAL_TIME
   * If this is not possible to add time before and/or after we are calculating and trying
   * to add (if is possible) whatever is left.
   */
  prepareVideoDataForTrimming(
    videoUrl: string,
    startOfVideoClip: number,
    durationVideoClip: number,
    durationOfFullVideo: number
  ): {
    videoUrl: string;
    startOfExtendedVideoClip: number;
    durationOfExtendedVideoClip: number;
  } {
    let startOfTrimVideoClip: number;
    let durationTrimVideoClip: number;

    // If duration is undefined try to set it to DEFAULT_DURATION or what is available
    durationVideoClip = durationVideoClip
      ? durationVideoClip
      : durationOfFullVideo - startOfVideoClip > VideoService.DEFAULT_DURATION
      ? VideoService.DEFAULT_DURATION
      : durationOfFullVideo - startOfVideoClip;

    durationTrimVideoClip = durationVideoClip;
    startOfTrimVideoClip = startOfVideoClip;

    // We want to allow ADDITIONAL_TIME seconds back video part
    if (startOfVideoClip > VideoService.ADDITIONAL_TIME) {
      startOfTrimVideoClip -= VideoService.ADDITIONAL_TIME;
      durationTrimVideoClip += VideoService.ADDITIONAL_TIME;
    } else {
      startOfTrimVideoClip = 0;
      durationTrimVideoClip += startOfVideoClip;
    }

    // We want to allow ADDITIONAL_TIME seconds beyond video part
    if (
      durationOfFullVideo - (startOfVideoClip + durationVideoClip) >
      VideoService.ADDITIONAL_TIME
    ) {
      durationTrimVideoClip += VideoService.ADDITIONAL_TIME;
    } else {
      durationTrimVideoClip +=
        durationOfFullVideo - (startOfVideoClip + durationVideoClip);
    }

    return {
      videoUrl: this.createMediaFragment(
        videoUrl,
        startOfTrimVideoClip,
        durationTrimVideoClip
      ),
      startOfExtendedVideoClip: startOfTrimVideoClip,
      durationOfExtendedVideoClip: durationTrimVideoClip
    };
  }

  createMediaFragment(
    src: string,
    startTime: number,
    duration: number
  ): string {
    return `${src}#t=${startTime},${Math.round(startTime + duration)}`;
  }

  getVideoClipSequence(
    startVideoTime: number,
    endVideoTime: number,
    videoTime: number,
    videoTrimOffset: number,
    videoClipType: VideoClipType
  ): {
    startVideoClipTimeSequence: number;
    durationVideoClipSequence: number;
  } {
    if (startVideoTime && endVideoTime) {
      return {
        startVideoClipTimeSequence: startVideoTime,
        durationVideoClipSequence: endVideoTime - startVideoTime
      };
    } else {
      /**
       * Calculate desired start time of sequence
       * by addition to videoTime of OFFSET of Camera Angle.
       *
       * In case of Goal slow prediction add aprox. prediction of GOAL_SLOW_SEQUENCE_TIME_START
       *
       * We always target aprox. prediction of DEFAULT_DURATION
       *
       */
      const aproxPrediction =
        videoClipType === VideoClipType.GOAL_SLOW_CLIP
          ? VideoService.GOAL_SLOW_SEQUENCE_TIME_START
          : 0;
      return {
        startVideoClipTimeSequence:
          videoTime + (videoTrimOffset ?? 0) + aproxPrediction,
        durationVideoClipSequence: VideoService.DEFAULT_DURATION
      };
    }
  }

  getVideoClipData(
    event: GameEvent,
    videoClipType: VideoClipType
  ): {
    startVideoClipTime: number | undefined;
    endVideoClipTime: number | undefined;
  } {
    if (videoClipType === VideoClipType.GOAL_SLOW_CLIP) {
      return {
        startVideoClipTime: event?.goalClip?.startVideoTime,
        endVideoClipTime: event?.goalClip?.endVideoTime
      };
    }
    if (videoClipType === VideoClipType.VIDEO_REVIEW_CLIP) {
      return {
        startVideoClipTime: event?.videoReviewClip?.startVideoTime,
        endVideoClipTime: event?.videoReviewClip?.endVideoTime
      };
    }
    return undefined;
  }
}
