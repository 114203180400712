import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BitrateOptions, VgApiService } from '@videogular/ngx-videogular/core';
import { VgHlsDirective } from '@videogular/ngx-videogular/streaming';
import { interval, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BroadcastAction, BroadcastService } from '../broadcast.service';
import { CharCodes } from '../domain/char-codes';
import { Game, Video } from '../domain/game';
import { EventType } from '../domain/game-event';
import { AlertService } from '../services/alert.service';
import { GameService } from '../services/game.service';
import { VideoService } from '../services/video.service';
import { StorageService } from '../storage.service';
import { VideoObjectTracksComponent } from '../video-object-tracks/video-object-tracks.component';
import {
  WebrtcBitrateMeasurements,
  WebrtcConnectionStats,
  WebrtcDirective
} from '../webrtc.directive';

import {
  selectVideoStatus,
  selectVideoTimeExternalReplay
} from '../state/reducers/game.reducer';
import {
  detectionsChange,
  videoTimeChange
} from '../state/actions/game-event.action';
import { videoStatusChange } from '../state/actions/game.action';
import { selectEventType } from '../state/reducers/game-event.reducer';
import { GlobalState } from '../state/reducers';
import { MatSlideToggle } from '@angular/material/slide-toggle';

const MEDIA_ELEMENT_ERROR = 4;

@Component({
  selector: 'app-video-player-window',
  templateUrl: './video-player-window.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { '(window:keydown)': 'hotkeys($event)' },
  styleUrls: ['./video-player-window.component.css']
})
export class VideoPlayerWindowComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  private static readonly PLAYBACK_HOTKEYS = [
    CharCodes.space_key_code,
    CharCodes.left_arrow_key_code,
    CharCodes.right_arrow_key_code,
    CharCodes.up_arrow_key_code,
    CharCodes.down_arrow_key_code
  ];

  private componentDestroyed$: Subject<void> = new Subject();
  private lastVideoTimeUpdate = 0;
  private startVideoTime;

  game: Game;
  mediaPlayerApi: VgApiService;
  homeTeam: string;
  awayTeam: string;
  videoUrl: string;
  channelIndex = 0;
  previousChannelIndex = 0;

  currentTime = 0;
  currentFrame = 0;
  rewindStop = null;
  onlyNumbers = true;
  videoPaused = true;
  trackingEnabled = false;
  editOffset = false;
  editOffsetStart = 0;

  @ViewChild(VideoObjectTracksComponent)
  videoObjectTracks: VideoObjectTracksComponent;
  filteredTeams: string[];

  videoWidth: number;
  videoHeight: number;
  frameRate: number;
  trackOffset: number;

  detectionMode: boolean;

  @ViewChild(VgHlsDirective)
  vgHls: VgHlsDirective;
  bitrates: BitrateOptions[];

  @ViewChild(WebrtcDirective)
  vgWebRtc: WebrtcDirective;
  hlsSeeking = false;
  connectionStats: WebrtcConnectionStats;
  bitrateMeasurement: WebrtcBitrateMeasurements;
  debugMode = false;
  durationWebRTC = 0; // timestamp

  selectedVideoMediaId: string;

  showGameContext = true;
  showIceRink = true;
  showPlayerPosition = true;
  showInput = true;

  swapSide = false;

  screensize: 'sm' | 'lg' = 'sm';
  eventType: EventType;

  hlsConfig: Record<string, unknown> = {};

  isLive = false;

  @HostListener('window:resize')
  updateScreenSize() {
    this.screensize = this.media.matchMedia('(max-width: 1200px)').matches
      ? 'sm'
      : 'lg';
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private gameService: GameService,
    private alertService: AlertService,
    private videoService: VideoService,
    private storageService: StorageService,
    private cdRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private store: Store<GlobalState>,
    private broadcast: BroadcastService
  ) {}

  ngOnInit() {
    this.debugMode =
      this.activatedRoute.snapshot.queryParams['debug'] === 'true';
    this.configHls();
    this.subscribeToReduxEvents();
    this.game = this.activatedRoute.snapshot.data['game'];
    this.videoService.prepareMediaData(this.game.videos ?? []);
    this.homeTeam = this.game.homeTeam;
    this.awayTeam = this.game.awayTeam;
    this.trackingEnabled =
      this.game.isCVProcessed ||
      this.activatedRoute.snapshot.queryParams['isCVProcessed'] === 'true';

    if ((this.game.videos ?? []).length > 0) {
      this.videoUrl = this.game.videos[0].urlSigned || this.game.videos[0].url;
      this.selectedVideoMediaId = this.game.videos[0].mediaId;
      this.frameRate =
        this.game.videos[0].frameRate ||
        this.getLeagueFrameRateDefault(this.game.league);
    }
    this.trackOffset = this.game.videos[0].trackOffset ?? 0;

    const videoTimeStr = this.activatedRoute.snapshot.queryParams['videoTime'];
    if (videoTimeStr) {
      const parts = videoTimeStr.split(':');
      this.startVideoTime =
        parseInt(parts[0], 10) * 3600 +
        parseInt(parts[1], 10) * 60 +
        parseInt(parts[2], 10);
      this.currentFrame = Math.floor(this.startVideoTime * this.frameRate);
    }
    this.updateScreenSize();
    this.showInput = this.storageService.getItem('showInputFields') ?? true;

    this.broadcast
      .listen()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((message) => {
        switch (message.data.type) {
          case BroadcastAction.ResetState:
          case BroadcastAction.SaveComplete:
            this.showInput =
              this.storageService.getItem('showInputFields') ?? true;
            break;
        }
      });
    interval(1000)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        if (this.mediaPlayerApi && this.mediaPlayerApi.isLive) {
          // mark as not live if the delay is more than 5 seconds
          const diffToLive =
            this.hls.liveSyncPosition - this.mediaPlayerApi.currentTime;
          this.isLive = diffToLive < 5;
        }
      });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  private getLeagueFrameRateDefault(league: string): number {
    if (league === '106') {
      // Liiga
      return 50;
    }
    return 25;
  }

  get isHLS(): boolean {
    return this.videoUrl && this.videoUrl.indexOf('.m3u8') > -1;
  }

  get isDASH(): boolean {
    return this.videoUrl && this.videoUrl.indexOf('.mpd') > -1;
  }

  get isWebRTC(): boolean {
    return (
      this.videoUrl &&
      (this.videoUrl.startsWith('ws://') || this.videoUrl.startsWith('wss://'))
    );
  }

  get isMP4(): boolean {
    return !this.isHLS && !this.isDASH && !this.isWebRTC;
  }

  get activeMediaId(): string {
    return this.selectedVideoMediaId;
  }

  private subscribeToReduxEvents() {
    this.store
      .select(selectVideoStatus)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((status) => {
        console.log('videoStatus', status);
        if (this.mediaPlayerApi) {
          if (status === 'pause') {
            this.mediaPlayerApi.pause();
          } else if (status === 'play') {
            this.mediaPlayerApi.play();
          } else {
            console.error('unknown video status', status);
          }
        }
      });

    this.store
      .select(selectVideoTimeExternalReplay)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ videoTime, random }) => {
        if (videoTime !== null && videoTime !== undefined) {
          if (this.isWebRTC) {
            this.switch(videoTime, false);
          } else {
            this.seekTo(videoTime);
          }
        }
      });

    this.store
      .select(selectEventType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => {
        this.eventType = newValue;
        if (newValue) {
          this.showInput = true;
        }
      });
  }

  onPlayerReady(api: VgApiService, mediaId: string) {
    console.log('onPlayerReady');
    this.mediaPlayerApi = api;
    const subscriptions =
      this.mediaPlayerApi.getMediaById(mediaId).subscriptions;
    subscriptions.loadedMetadata.pipe(first()).subscribe((e) => {
      console.log(
        'loaded video metadata',
        e.srcElement.videoWidth,
        e.srcElement.videoHeight
      );
      this.videoWidth = e.srcElement.videoWidth;
      this.videoHeight = e.srcElement.videoHeight;

      if (
        this.startVideoTime &&
        this.mediaPlayerApi &&
        !this.isHLS &&
        !this.isWebRTC
      ) {
        this.seekTo(this.startVideoTime);
      }

      if (this.isWebRTC) {
        this.determineWebRTCStreamDuration();
      }
    });
    this.publishVideoTimeUpdates();

    subscriptions.pause.subscribe(async (e) => {
      this.store.dispatch(videoStatusChange({ videoStatus: 'pause' }));
      let currentTime = e.srcElement.currentTime;
      if (this.isWebRTC) {
        currentTime += this.durationWebRTC / 1000;
      }

      await this.saveAsQueryParameter(currentTime);
      this.videoPaused = true;
    });
    subscriptions.play.subscribe(() => {
      this.store.dispatch(videoStatusChange({ videoStatus: 'play' }));
      this.videoPaused = false;
    });

    const updateRate = Math.floor(1 / this.frameRate);
    subscriptions.play.subscribe(() => {
      interval(updateRate)
        .pipe(filter(() => !this.videoPaused))
        .subscribe(() => {
          this.currentTime = this.getCurrentTime();
          this.currentFrame = Math.floor(this.currentTime * this.frameRate);
        });
    });
    subscriptions.seeked.subscribe(() => {
      if (this.videoPaused) {
        this.currentFrame = Math.floor(this.getCurrentTime() * this.frameRate);
      }
    });
  }

  private async saveAsQueryParameter(currentTime) {
    const videoTime = this.formatHHMMSS(currentTime);
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        videoTime
      },
      queryParamsHandling: 'merge'
    });
  }

  private formatHHMMSS(seconds: number) {
    const hh = Math.floor(seconds / 3600);
    const mm = Math.floor((seconds - hh * 3600) / 60);
    const ss = Math.floor(seconds - hh * 3600 - mm * 60);
    return (
      hh.toString().padStart(2, '0') +
      ':' +
      mm.toString().padStart(2, '0') +
      ':' +
      ss.toString().padStart(2, '0')
    );
  }

  private publishVideoTimeUpdates() {
    console.log('default media', this.mediaPlayerApi.getDefaultMedia());
    this.mediaPlayerApi
      .getDefaultMedia()
      .subscriptions.timeUpdate.subscribe((e) => {
        const diffSec = Math.abs(
          this.getCurrentTime() - (this.lastVideoTimeUpdate || 0)
        );
        if (diffSec >= 0.5) {
          this.store.dispatch(
            videoTimeChange({ videoTime: this.getCurrentTime() })
          );
          this.lastVideoTimeUpdate = this.currentTime;
        }
      });
  }

  seekTo(videoTime: number) {
    if (this.mediaPlayerApi != null && videoTime != null) {
      console.log('seekTo', videoTime);
      this.mediaPlayerApi.seekTime(videoTime);
    }
  }

  async seek(seconds: number) {
    if (this.mediaPlayerApi != null) {
      if (this.isWebRTC) {
        await this.switch(100);
      } else {
        const newCurrentVideoTime = Math.max(
          this.mediaPlayerApi.getDefaultMedia().currentTime.valueOf() + seconds,
          0
        );
        this.mediaPlayerApi.seekTime(newCurrentVideoTime);
      }
    }
  }

  get videoTime(): number {
    if (this.mediaPlayerApi) {
      return this.getCurrentTime();
    } else {
      return null;
    }
  }

  hotkeys(event) {
    if (this.mediaPlayerApi != null && this.mediaPlayerApi.isPlayerReady) {
      if (VideoPlayerWindowComponent.PLAYBACK_HOTKEYS.includes(event.keyCode)) {
        this.handlePlayBackHotKey(event);
      }
    }
  }

  private handlePlayBackHotKey(event) {
    const keyCode = event.keyCode;
    if (keyCode === CharCodes.space_key_code) {
      event.preventDefault();
      if (this.mediaPlayerApi.state === 'playing') {
        this.mediaPlayerApi.pause();
      } else {
        this.mediaPlayerApi.play();
      }
    } else if (keyCode === CharCodes.left_arrow_key_code) {
      this.seek(-1);
    } else if (keyCode === CharCodes.right_arrow_key_code) {
      this.seek(1);
    } else if (keyCode === CharCodes.up_arrow_key_code) {
      event.preventDefault();
      this.mediaPlayerApi.playbackRate =
        Math.round((this.mediaPlayerApi.playbackRate + 0.1) * 100) / 100;
    } else if (keyCode === CharCodes.down_arrow_key_code) {
      event.preventDefault();
      if (this.mediaPlayerApi.playbackRate > 0) {
        this.mediaPlayerApi.playbackRate =
          Math.round((this.mediaPlayerApi.playbackRate - 0.1) * 100) / 100;
      }
    }
  }

  containerClicked(event: MouseEvent) {
    if (this.videoObjectTracks) {
      this.videoObjectTracks.containerClicked(event);
    }
  }

  onlyNumbersChanged(value: boolean) {
    this.onlyNumbers = value;
  }

  toggleTracking() {
    this.trackingEnabled = !this.trackingEnabled;
    if (!this.trackingEnabled) {
      this.store.dispatch(detectionsChange({ detections: [] }));
    }
  }

  switchChannel(selectedIndex: number) {
    const previousOffset =
      this.game.videos[this.previousChannelIndex].offset || 0;
    this.previousChannelIndex = selectedIndex;
    const video = this.game.videos[this.channelIndex];

    const offset = (video.offset || 0) - previousOffset;
    this.startVideoTime = this.getCurrentTime() + offset;

    this.videoUrl = video.urlSigned || video.url;
    this.selectedVideoMediaId = video.mediaId;

    this.cdRef.detectChanges();

    this.mediaPlayerApi
      .getMediaById(this.selectedVideoMediaId)
      .subscriptions.loadedMetadata.pipe(first())
      .subscribe(() => {
        console.log('loadedMetadata');
        if (
          this.startVideoTime &&
          this.mediaPlayerApi &&
          !this.isHLS &&
          !this.isWebRTC
        ) {
          this.seekTo(this.startVideoTime);
        }

        if (this.isWebRTC) {
          this.determineWebRTCStreamDuration();
        }
      });
  }

  get selectedVideo(): Video {
    if (!this.game.videos) {
      return {} as Video;
    }
    return this.game.videos[this.channelIndex];
  }

  setBitrate(option: BitrateOptions) {
    if (this.vgHls) {
      this.vgHls.setBitrate(option);
    } else if (this.vgWebRtc) {
      this.vgWebRtc.setBitrate(option);
    }
  }

  availableBitrates(bitrates: BitrateOptions[]) {
    console.log('available bitrates', bitrates);
    this.bitrates = bitrates.map((b) => {
      if (b.height > 0) {
        b.label = b.height + 'p';
      }
      return b;
    });
  }

  frameRateChange(frameRate: number) {
    this.frameRate = frameRate;
    this.currentFrame = Math.floor(this.currentTime * this.frameRate);
  }

  startFastRewind() {
    this.editOffsetStart = this.getCurrentTime();
    this.rewind(4.0);
  }

  endFastRewind() {
    this.rewindStop.next();
    this.mediaPlayerApi.playbackRate = 1.0;
    this.mediaPlayerApi.pause();
    this.selectedVideo.offset -= this.getCurrentTime() - this.editOffsetStart;
    this.editOffsetStart = 0;
  }

  startFastForward() {
    this.editOffsetStart = this.getCurrentTime();
    this.mediaPlayerApi.playbackRate = 4.0;
    this.mediaPlayerApi.play();
  }

  endFastForward() {
    this.mediaPlayerApi.playbackRate = 1.0;
    this.mediaPlayerApi.pause();
    this.selectedVideo.offset += this.getCurrentTime() - this.editOffsetStart;
    this.editOffsetStart = 0;
  }

  addFrameOffset() {
    const oneFrame = 1 / (this.selectedVideo.frameRate || 25);
    this.selectedVideo.offset += oneFrame;
    this.mediaPlayerApi.currentTime += oneFrame;
  }

  subtractFrameOffset() {
    const oneFrame = 1 / (this.selectedVideo.frameRate || 25);
    this.selectedVideo.offset -= oneFrame;
    this.mediaPlayerApi.currentTime -= oneFrame;
  }

  saveOffsets() {
    this.gameService
      .update(this.game._id, { videos: this.game.videos })
      .subscribe(() => {
        this.editOffset = !this.editOffset;
        this.alertService.showInfo('Video offsets saved');
      });
  }

  rewind(rewindSpeed: number) {
    const startSystemTime = new Date().getTime();
    const startVideoTime = this.getCurrentTime();
    this.rewindStop = new Subject<void>();

    interval(30)
      .pipe(takeUntil(this.rewindStop))
      .subscribe(() => {
        this.mediaPlayerApi.playbackRate = 1.0;
        if (this.mediaPlayerApi.currentTime === 0) {
          this.rewindStop.next();
          this.mediaPlayerApi.pause();
        } else {
          const elapsed = new Date().getTime() - startSystemTime;
          this.mediaPlayerApi.currentTime = Math.max(
            startVideoTime - (elapsed * rewindSpeed) / 1000.0,
            0
          );
        }
      });
  }

  get webRtcExists() {
    return !this.isWebRTC && this.getVideoByFormat('webrtc') !== undefined;
  }

  get hls() {
    return this.vgHls?.hls;
  }

  hlsSeekToLive() {
    const media = this.mediaPlayerApi?.getDefaultMedia();
    const position = this.hls.liveSyncPosition;
    if (media && position) {
      media.currentTime = position;
    }
  }

  switchToWebRTC() {
    const video = this.getVideoByFormat('webrtc');
    this.videoUrl = video.urlSigned || video.url;
    this.selectedVideoMediaId = video.mediaId;
    this.channelIndex = video.index;

    this.hlsSeeking = false;
    this.cdRef.detectChanges();
  }

  getVideoByFormat(format: string): Video {
    const currentVideo = this.game.videos[this.channelIndex];
    return this.game.videos.find(
      (v) => v.format === format && v.cameraAngle === currentVideo?.cameraAngle
    );
  }

  detectionModeChange(detectionMode: boolean) {
    this.detectionMode = detectionMode;
  }

  async switch(value: number, byPercent: boolean = true) {
    const video = this.getVideoByFormat('hls');
    this.videoUrl = video.urlSigned || video.url;
    this.selectedVideoMediaId = video.mediaId;
    this.channelIndex = video.index;
    this.hlsSeeking = true;
    this.cdRef.detectChanges();

    await this.videoLoaded(this.selectedVideoMediaId);
    const media: any = this.mediaPlayerApi.getMediaById(
      this.selectedVideoMediaId
    );
    media.seekTime(value, byPercent);
  }

  async videoLoaded(mediaId: string) {
    const media = this.mediaPlayerApi?.getMediaById(mediaId).subscriptions;
    const loaded$ = new Subject<void>();
    return new Promise<void>((resolve, reject) => {
      media.loadedMetadata.pipe(takeUntil(loaded$)).subscribe(() => {
        loaded$.next();
        console.log('video loaded');
        resolve();
      });
      media.error.pipe(takeUntil(loaded$)).subscribe((e) => {
        loaded$.next();
        const errorMessage = e.target?.error?.message;
        const errorCode = e.target?.error?.code;
        console.log(
          `video loaded error: code=${errorCode}, message=${errorMessage}`
        );
        if (errorCode !== MEDIA_ELEMENT_ERROR) {
          reject(e);
        }
      });
    });
  }

  updateConnectionStats(stats: WebrtcConnectionStats) {
    console.log('[WebRTC] connection stats', stats);
    this.connectionStats = stats;
  }

  updateBitrateMeasurement(measurement: WebrtcBitrateMeasurements) {
    console.log('[WebRTC] bitrate measurement', measurement);
    this.bitrateMeasurement = measurement;
  }

  determineWebRTCStreamDuration() {
    const video = this.game.videos.find((v) => v.url === this.videoUrl);
    if (!video) {
      throw new Error('Could not find video for URL: ' + this.videoService);
    }
    this.videoService
      .getWebRTCStreamDetails(this.game._id, video.id)
      .subscribe((res) => {
        this.durationWebRTC =
          new Date().getTime() - new Date(res.startTime).getTime();
      });
  }

  getCurrentTime() {
    return this.isWebRTC
      ? this.mediaPlayerApi.currentTime + this.durationWebRTC / 1000
      : this.mediaPlayerApi.currentTime;
  }

  get mediaIds(): string[] {
    return this.game.videos && this.game.videos.length > 0
      ? this.game.videos.map((v: any) => v.mediaId)
      : ['video-dash-0', 'video-vod-0', 'video-webrtc-0', 'video-hls-0'];
  }

  requiresIceRink(eventType: EventType) {
    return [
      'face_off',
      'shot',
      'pass',
      'puckPossession',
      'videoTag',
      'oddMenRush',
      'highlight'
    ].includes(eventType);
  }

  changeShowInputFields(showInput: MatSlideToggle) {
    this.showInput = showInput.checked;
    this.storageService.setItem('showInputFields', this.showInput);
  }

  onKeyTyped() {
    this.showInput = true;
  }

  configHls() {
    this.hlsConfig = {
      debug: this.debugMode,
      xhrSetup(xhr: XMLHttpRequest, url: string) {
        if (url.includes('49ing.ch') && url.includes('.m3u8')) {
          url = url.replace('.m3u8', '.m3u8?ngsw-bypass=true');
          xhr.open('GET', url, true);
        }
      },
      fetchSetup(context, initParams: RequestInit) {
        if (context.url.includes('49ing.ch') && context.url.includes('.m3u8')) {
          const url = context.url.replace('.m3u8', '.m3u8?ngsw-bypass=true');
          return new Request(url, initParams);
        }
        return new Request(context.url, initParams);
      },
      enableWorker: true,
      lowLatencyMode: true,
      backBufferLength: 90,
      liveSyncDuration: 4.5
    };
  }
}
