<h2 mat-dialog-title>Video Clip</h2>
<mat-dialog-content>
  <div><em>Please mark start and end of video clip</em></div>
  <app-video-player-trim
    [game]="game"
    [cameraIndex]="cameraIndex"
    [src]="src"
    [startOfVideoClip]="startOfVideoClip"
    [durationVideoClip]="durationVideoClip"
    [durationOfFullVideo]="durationOfFullVideo"
    [startOfOriginalVideoClip]="startOfOriginalVideoClip"
    [durationOfOriginalVideoClip]="durationOfOriginalVideoClip"
    (updateTrimVideo)="updateTrimVideo($event)"
    (changeCameraAngle)="changeCameraAngle($event)"
  >
  </app-video-player-trim>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button="" color="primary" (click)="apply()">Apply</button>
  <button mat-button [mat-dialog-close]="{}">Cancel</button>
</mat-dialog-actions>
