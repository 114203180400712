<mat-form-field>
  <mat-select [(value)]="event.officialsCallSituation">
    <mat-option [value]="null"></mat-option>
    <mat-option
      *ngFor="let option of allOfficialsCallSituations"
      [value]="option.key"
    >
      {{ option.description }}
    </mat-option>
  </mat-select>
</mat-form-field>
