<mat-form-field>
  <mat-select
    [(value)]="event.gameIncidentType"
    (selectionChange)="selectionChange.emit($event)"
  >
    <mat-option
      *ngFor="let option of allGameIncidentTypes"
      [value]="option.key"
    >
      {{ option.description }}
    </mat-option>
  </mat-select>
</mat-form-field>
