import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GameEvent, OfficialsCallAction } from '../../../domain/game-event';

@Component({
  selector: 'app-officiating-call-action',
  templateUrl: './officiating-call-action.component.html',
  styleUrls: ['./officiating-call-action.component.css']
})
export class OfficiatingCallActionComponent {
  @Input()
  event: GameEvent;

  @Output()
  selectionChange = new EventEmitter<void>();

  allOfficialsCallActions: {
    key: OfficialsCallAction;
    description: string;
  }[] = [
    { key: 'call', description: 'Call' },
    { key: 'non-call', description: 'Non-Call' }
  ];
}
