import { Injectable } from '@angular/core';
import { GameEvent } from '../domain/game-event';

@Injectable({
  providedIn: 'root'
})
export class GameIncidentService {
  constructor() {}

  isGameIncidentTypeSet(event: GameEvent) {
    return event?.eventType === 'game_incident' && !!event?.gameIncidentType;
  }

  isWrongUncalledPenaltyEvent(event: GameEvent) {
    return (
      event?.eventType === 'game_incident' &&
      event?.gameIncidentType === 'penalty' &&
      event?.officialsCallAssessment === 'wrong' &&
      event?.officialsCallAction === 'non-call'
    );
  }

  isCalledPenaltyEvent(event: GameEvent) {
    return (
      event?.eventType === 'game_incident' &&
      event?.gameIncidentType === 'penalty' &&
      event?.officialsCallAction === 'call'
    );
  }

  isPenaltyEvent(event?: GameEvent) {
    const res =
      event?.eventType === 'game_incident' &&
      event?.gameIncidentType === 'penalty';
    return res;
  }

  isIcingEvent(event?: GameEvent) {
    const res =
      event?.eventType === 'game_incident' &&
      event?.gameIncidentType === 'icing';
    return res;
  }

  private updateUncalledPenaltySeverity(event?: GameEvent) {
    if (!this.isWrongUncalledPenaltyEvent(event)) {
      event.officialsUncalledPenaltySeverity = null;
    }
  }

  isValidUncalledPenaltyEvent(event?: GameEvent) {
    return !(
      this.isWrongUncalledPenaltyEvent(event) &&
      !event?.officialsUncalledPenaltySeverity
    );
  }

  private updateCalledPenaltySeverity(event?: GameEvent) {
    if (!this.isCalledPenaltyEvent(event)) {
      event.officialsPenaltyCallSeverity = null;
    } else if (!event.officialsPenaltyCallSeverity) {
      event.officialsPenaltyCallSeverity = 'unspecified';
    }
  }

  applyDefaultValues(event?: GameEvent) {
    if (event?.eventType !== 'game_incident') {
      return;
    }
    if (!event.officialsCallAssessment) {
      event.officialsCallAssessment = 'unspecified';
    }

    // update specific fields based on gameIncidentType
    if (event.gameIncidentType === 'penalty') {
      this.updateUncalledPenaltySeverity(event);
      this.updateCalledPenaltySeverity(event);
    }

    // remove unrelated fields to gameIncidentType
    if (event.gameIncidentType !== 'penalty') {
      event.gameIncidentReason = null;
      event.officialsUncalledPenaltySeverity = null;
      event.officialsPenaltyCallSeverity = null;
      event.isGameIncidentWithPlayerInjury = null;
    }
    if (event.gameIncidentType !== 'icing') {
      event.isIcingRaceCondition = null;
    }
  }
}
