import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { interval, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { BroadcastAction, BroadcastService } from '../broadcast.service';
import { Game, GameStatus } from '../domain/game';
import { GameEvent, GamePeriod, StrengthState } from '../domain/game-event';
import { Player } from '../domain/player';
import { Shift } from '../domain/shift';
import { AlertService } from '../services/alert.service';
import { EventService } from '../services/event.service';
import { GameTimeService } from '../services/game-time.service';
import { PuckPossessionStateService } from '../services/puck-possession-state.service';
import { ShiftsService } from '../services/shifts.service';
import { StrengthStateService } from '../services/strength-state.service';
import {
  periodChange,
  resetState as resetStateGameEvent
} from '../state/actions/game-event.action';

import {
  resetState as resetStateGame,
  videoStatusChange,
  videoTimeExternalChange
} from '../state/actions/game.action';
import { GlobalState } from '../state/reducers';
import {
  selectEventType,
  selectFaceOffOpponentPlayerNumber,
  selectGameIncidentType,
  selectGameIncidentReason,
  selectGameTime,
  selectInterruption_type,
  selectIsAwayTeamEmptyNet,
  selectIsHomeTeamEmptyNet,
  selectPeriod,
  selectPlayerNumber,
  selectStrengthState,
  selectTeam,
  selectTeamFaceOffOutcome,
  selectVideoTime,
  selectOfficialsCallAction,
  selectOfficialsCallSituation
} from '../state/reducers/game-event.reducer';
import {
  selectGameId,
  selectVideoStatus
} from '../state/reducers/game.reducer';
import { EventsOverviewComponent } from './events-overview/events-overview.component';

interface TeamPlayers {
  goalkeepers: Player[];
  defenders: {
    1: Player[];
    2: Player[];
    3: Player[];
    4: Player[];
    5: Player[];
  };
  forwards: {
    1: Player[];
    2: Player[];
    3: Player[];
    4: Player[];
    5: Player[];
  };
}

@Component({
  selector: 'app-time-on-ice',
  templateUrl: './time-on-ice.component.html',
  styleUrls: ['./time-on-ice.component.css']
})
export class TimeOnIceComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();

  game: Game;

  period: GamePeriod;
  strengthState: StrengthState = '5-5';
  isHomeTeamEmptyNet = false;
  isAwayTeamEmptyNet = false;
  gameTime = 0.0;
  playing = false;

  selectedTeam: string;
  selectedPlayerNumber: string;
  videoTime: number;
  event: GameEvent = {} as GameEvent;

  strictPositions = [false, false];
  homePlayers: TeamPlayers;
  awayPlayers: TeamPlayers;
  onIce: Shift[] = [];
  events: GameEvent[] = [];
  shifts: Shift[] = [];
  activeShifts: { [playerNumber: string]: Shift } = {};

  @ViewChild(EventsOverviewComponent)
  eventsOverviewComponent: EventsOverviewComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private alertService: AlertService,
    private strengthStateService: StrengthStateService,
    private gameTimeService: GameTimeService,
    private shiftsService: ShiftsService,
    private puckPossessionStateService: PuckPossessionStateService,
    private title: Title,
    private snackbar: MatSnackBar,
    private logger: NGXLogger,
    private broadcast: BroadcastService,
    private store: Store<GlobalState>
  ) {}

  ngOnInit() {
    this.game = this.activatedRoute.snapshot.data['game'];
    this.title.setTitle(
      this.game.homeTeam + '-' + this.game.awayTeam + ' > Time on Ice'
    );
    this.strengthStateService.init(this.game._id);
    this.gameTimeService.init(this.game._id);
    this.puckPossessionStateService.init(this.game._id);
    this.updatePlayers();
    this.loadShifts();
    this.loadEvents();
    this.subscribeToUpdates();
    this.subscribeToReduxEvents();
    this.startWallClock();
  }

  private loadShifts() {
    this.shiftsService.getShifts(this.game).subscribe((shifts) => {
      this.shifts = shifts;
      this.updateActiveShifts(shifts, this.gameTime, this.period);
    });
  }

  private loadEvents() {
    this.eventService
      .getEvents(this.game._id, { eventType: 'time_on_ice' }, true, 0, 10000)
      .subscribe((events) => {
        this.events = events[1];
        this.events.sort((a, b) => b.videoTime - a.videoTime);
      });
  }

  private subscribeToUpdates() {
    if (
      [GameStatus.FAILED, GameStatus.ABANDONED, GameStatus.COMPLETE].includes(
        this.game.status
      )
    ) {
      return;
    }
    this.eventService
      .getEventsAsStream(this.game._id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((event) => {
        console.log('Event update received', event);
        if (event.deleted) {
          this.removeEvent(event);
          if (
            event.eventType === 'face_off' ||
            event.eventType === 'interruption'
          ) {
            this.gameTimeService.deleteTimeEvent(event._id);
          } else if (event.eventType === 'time_on_ice') {
            this.strengthStateService.deleteAndDispatchTimeOnIce(event);
          } else if (event.eventType === 'puckPossession') {
            this.puckPossessionStateService.deleteAndDispatchPuckPossessionEvent(
              event,
              this.videoTime
            );
          }
          return;
        }
        this.addEvent(event);

        if (
          event.eventType === 'face_off' ||
          event.eventType === 'interruption'
        ) {
          this.gameTimeService.addAndDispatchTimeEvent(event, this.videoTime);
        } else if (event.eventType === 'puckPossession') {
          this.puckPossessionStateService.addAndDispatchPuckPossessionEvent(
            event,
            this.videoTime
          );
        }

        if (event.eventType === 'penalty') {
          this.strengthStateService.addAndDispatchActivePenalty(
            event,
            this.gameTime
          );
          this.strengthStateService.updateStrengthState(this.videoTime);
        }

        if (event.eventType === 'time_on_ice') {
          this.strengthStateService.addAndDispatchTimeOnIce(event);
          this.strengthStateService.updateHomeTeamEmptyNet(this.videoTime);
          this.strengthStateService.updateAwayTeamEmptyNet(this.videoTime);
        }
      });
  }

  private updatePlayers(): void {
    this.homePlayers = this.mapPlayers(this.game.homeTeam);
    this.awayPlayers = this.mapPlayers(this.game.awayTeam);
  }

  private mapPlayers(team: string): TeamPlayers {
    return {
      goalkeepers: this.getGoalkeepers(team),
      defenders: {
        1: this.getDefenders(1, team),
        2: this.getDefenders(2, team),
        3: this.getDefenders(3, team),
        4: this.getDefenders(4, team),
        5: this.getDefenders(5, team)
      },
      forwards: {
        1: this.getForwards(1, team),
        2: this.getForwards(2, team),
        3: this.getForwards(3, team),
        4: this.getForwards(4, team),
        5: this.getForwards(5, team)
      }
    };
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(null);
  }

  get currentGameTime(): number {
    return this.gameTime;
  }

  private subscribeToReduxEvents(): void {
    this.store
      .select(selectGameId)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe((gameId) => {
        if (gameId !== this.game._id) {
          this.logger.info(
            'reset redux state for game switch',
            gameId,
            this.game._id
          );
          this.resetState();
        }
      });
    this.store
      .select(selectTeam)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((team) => (this.selectedTeam = team));
    this.store
      .select(selectVideoTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((videoTime) => this.updateVideoTime(videoTime));
    this.store
      .select(selectGameTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((gameTime) => this.updateGameTime(gameTime));
    this.store
      .select(selectStrengthState)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((strengthState) => (this.strengthState = strengthState));
    this.store
      .select(selectPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((playerNumber) => (this.selectedPlayerNumber = playerNumber));
    this.store
      .select(selectEventType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.eventType = newValue));
    this.store
      .select(selectInterruption_type)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.interruption_type = newValue));
    this.store
      .select(selectGameIncidentReason)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.gameIncidentReason = newValue));
    this.store
      .select(selectGameIncidentType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.gameIncidentType = newValue));
    this.store
      .select(selectOfficialsCallAction)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.officialsCallAction = newValue));
    this.store
      .select(selectOfficialsCallSituation)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.officialsCallSituation = newValue));
    this.store
      .select(selectTeamFaceOffOutcome)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.teamFaceOffOutcome = newValue));
    this.store
      .select(selectFaceOffOpponentPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.faceoff_opponent = newValue));
    this.store
      .select(selectPeriod)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((period) => this.updatePeriod(period));
    this.store
      .select(selectIsHomeTeamEmptyNet)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isEmpty) => (this.isHomeTeamEmptyNet = isEmpty));
    this.store
      .select(selectIsAwayTeamEmptyNet)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isEmpty) => (this.isAwayTeamEmptyNet = isEmpty));
    this.store
      .select(selectVideoStatus)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((status) => {
        this.logger.info('video status updated externally', status);
        if (status === 'play') {
          this.play(true);
        } else if (status === 'pause') {
          this.pause(true);
        } else {
          this.logger.error('unknown video status', status);
        }
      });

    this.broadcast.listen().subscribe(async (message) => {
      switch (message.data.type) {
        case BroadcastAction.Save:
          this.save();
          break;
        default:
          break;
      }
    });
  }

  private startWallClock() {
    if (
      this.game.lagClock &&
      (this.game.status === 'in_collection' ||
        this.game.status === 'in_extended_collection')
    ) {
      interval(1000)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.gameTimeService.updateElapsedTime(this.videoTime);
        });
    }
  }

  play(startedExternally: boolean): void {
    this.logger.info('play', startedExternally);
    this.playing = true;
    if (!startedExternally) {
      this.store.dispatch(videoStatusChange({ videoStatus: 'play' }));
    }
  }

  pause(startedExternally: boolean): void {
    this.logger.info('pause', startedExternally);
    this.playing = false;
    if (!startedExternally) {
      this.store.dispatch(videoStatusChange({ videoStatus: 'pause' }));
    }
  }

  emptyNetChanged(team: string): void {
    // end GK shift
  }

  periodChanged(period: GamePeriod): void {
    this.period = period;
    this.store.dispatch(periodChange({ period: this.period }));
    this.updateActiveShifts(this.shifts, this.gameTime, this.period);
  }

  private updateVideoTime(videoTime: number): void {
    this.videoTime = videoTime;
    this.logger.debug('update videoTime', this.videoTime);
    this.gameTimeService.updatePeriodAndGameTime(videoTime);
    this.puckPossessionStateService.updatePuckPossessionState(videoTime);
    this.strengthStateService.updateStrengthState(videoTime);
    this.strengthStateService.updateActivePenalties(this.gameTime);
    this.strengthStateService.updateHomeTeamEmptyNet(videoTime);
    this.strengthStateService.updateAwayTeamEmptyNet(videoTime);
  }

  private updateGameTime(gameTime: number): void {
    this.gameTime = gameTime;
    this.updateActiveShifts(this.shifts, this.gameTime, this.period);
  }

  private updatePeriod(period: GamePeriod) {
    this.logger.debug('update period', period);
    return (this.period = period);
  }

  seekEvent($event: GameEvent) {
    this.logger.debug('seekEvent', $event);
    this.period = $event.period;
    this.store.dispatch(periodChange({ period: $event.period }));
    this.store.dispatch(
      videoTimeExternalChange({
        videoTimeExternal: $event.videoTime,
        random: self.crypto.randomUUID()
      })
    );
    this.updateActiveShifts(this.shifts, $event.gameTime, $event.period);
  }

  save(): void {
    if (this.event.eventType === 'time_on_ice') {
      if (!this.selectedTeam) {
        this.logger.error('no team selected');
        return;
      }
      if (!this.selectedPlayerNumber) {
        this.logger.error('no player number selected');
        return;
      }
      const player = this.game.getPlayerObj(
        this.selectedPlayerNumber,
        this.selectedTeam
      );

      this.togglePlayer(player);
      this.resetState();
    }
  }

  private resetState() {
    this.selectedTeam = null;
    this.selectedPlayerNumber = null;

    const currentPeriod = this.period;
    const currentTeam = this.selectedTeam;
    this.store.dispatch(resetStateGame({ gameId: this.game._id }));
    this.store.dispatch(
      resetStateGameEvent({ period: currentPeriod, team: currentTeam })
    );
  }

  private isOnIce(playerId: string): boolean {
    return (
      this.onIce.find((s) => s.player && s.player.playerId === playerId) !==
      undefined
    );
  }

  togglePlayer(player: Player): void {
    if (this.isOnIce(player.playerId)) {
      const shift = this.onIce.find(
        (s) => s.player.playerId === player.playerId
      );
      this.endShift(shift);
    } else {
      const substitutedPlayer = this.onIce.find(
        (s) =>
          s.player.team === player.team &&
          s.player.position === player.position &&
          s.player.positionIndex === player.positionIndex
      );
      const strictPositions =
        this.strictPositions[player.team === this.game.homeTeam ? 0 : 1];
      if (substitutedPlayer && strictPositions) {
        this.endShift(substitutedPlayer);
      }
      this.startShift(player);
    }
  }

  startShift(player: Player): void {
    if (!player || !player.playerId) {
      console.error('cannot start shift - playerId missing', player);
      this.alertService.showError(
        'cannot start shift - playerId missing: ' + player.playerNumber
      );
      return;
    }
    this.logger.info('start shift', this.currentGameTime, player.playerNumber);

    const event = this.createTimeOneIceEvent(
      player.playerId,
      player.playerNumber
    );
    event.timeOnIceType = 'on';
    event.teamId = player.teamId;
    event.team = player.team;

    this.eventService.save(event).subscribe(
      (onEvent) => {
        const shift = {
          player,
          onEvent,
          offEvent: null
        } as Shift;
        this.onIce.push(shift);
        this.shifts.unshift(shift);
        this.addEvent(onEvent);
        this.activeShifts[player.playerNumber] = shift;
      },
      (e) => {
        this.alertService.showError(
          'could not save time on ice event: ' + e.message
        );
      }
    );
  }

  private endShift(shift: Shift) {
    this.logger.info(
      'end shift',
      this.currentGameTime,
      shift.player.playerNumber
    );

    const event = this.createTimeOneIceEvent(
      shift.player.playerId,
      shift.player.playerNumber
    );
    event.timeOnIceType = 'off';
    event.teamId = shift.player.teamId;
    event.team = shift.player.team;

    event.strengthState =
      this.strengthStateService.deriveTimeOnIceOffEventStrengthState(
        event.gameTime
      );

    this.eventService.save(event).subscribe(
      (offEvent) => {
        this.onIce.splice(this.onIce.indexOf(shift), 1);
        shift.offEvent = offEvent;
        this.addEvent(offEvent);
        this.activeShifts[shift.player.playerNumber] = null;
      },
      (e) => {
        this.alertService.showError(
          'could not save time on ice event: ' + e.message
        );
      }
    );
  }

  private createTimeOneIceEvent(
    playerId: string,
    playerNumber: string
  ): GameEvent {
    const event: GameEvent = {} as GameEvent;
    event.gameId = this.game._id;
    event.eventType = 'time_on_ice';
    event.period = this.period;
    event.playerId = playerId;
    event.playerNumber = playerNumber;
    event.videoTime = this.videoTime;
    event.gameTime = this.gameTime;
    event.strengthState = this.strengthState;
    event.isHomeTeamEmptyNet = this.isHomeTeamEmptyNet;
    event.isAwayTeamEmptyNet = this.isAwayTeamEmptyNet;
    return event;
  }

  deleteEvent(event: GameEvent) {
    this.eventService.delete(event.gameId, event._id).subscribe(
      () => {
        this.removeEvent(event);

        const j = this.shifts.findIndex(
          (s) => s.onEvent && s.onEvent._id === event._id
        );
        if (j > -1) {
          this.shifts.splice(j, 1);
        }
        const shift = this.shifts.find(
          (s) => s.offEvent && s.offEvent._id === event._id
        );
        if (shift) {
          shift.offEvent = null;
        }

        this.snackbar
          .open('Event deleted', 'Undo', { duration: 5000 })
          .onAction()
          .subscribe(() => {
            this.undoDelete(event);
          });

        this.updateActiveShifts(this.shifts, this.gameTime, this.period);
      },
      (error) => {
        console.log('delete failed', error);
        this.alertService.showError('Delete event failed: ' + error.message);
      }
    );
  }

  private addEvent(event: GameEvent) {
    const i = this.events.findIndex((e) => e._id === event._id);
    if (i === -1) {
      // insert at the start
      this.events.unshift(event);
    } else if (i > -1) {
      // replace existing event
      this.events[i] = event;
    }
    this.events = [...this.events];
  }

  private removeEvent(event: GameEvent) {
    const i = this.events.findIndex((e) => e._id === event._id);
    if (i > -1) {
      this.events.splice(i, 1);
      this.events = [...this.events];
    }
  }

  private undoDelete(event: GameEvent): any {
    this.eventService.save(event).subscribe(
      () => {
        console.log('undo delete successful', event);
        this.addEvent(event);
        this.loadShifts();
        this.alertService.showInfo('Undo delete successful');
      },
      (error) => {
        console.log('undo delete failed', event);
        this.alertService.showError('Undo delete failed: ' + error.message);
      }
    );
  }

  toggleDefenseLine(line: number, team: string): void {
    this.onIce
      .filter((s) => s.player.team === team && s.player.position === 'defender')
      .forEach((s) => this.endShift(s));
    this.getDefenders(line, team).forEach((p) => this.startShift(p));
  }

  toggleForwardLine(line: number, team: string): void {
    this.onIce
      .filter((s) => s.player.team === team && s.player.position === 'forward')
      .forEach((s) => this.endShift(s));
    this.getForwards(line, team).forEach((p) => this.startShift(p));
  }

  private getGoalkeepers(team: string): Player[] {
    const goalkeepers = this.game.getGoalkeepers(team);
    return goalkeepers
      .filter((playerNumber) => playerNumber && playerNumber !== 'n/a')
      .map((playerNumber) => this.game.getPlayerObj(playerNumber, team));
  }

  private getDefenders(line: number, team: string): Player[] {
    const defenders = this.game.getDefensemen(team);
    const dIndex = (line - 1) * 2;
    const dpair = defenders.slice(dIndex, dIndex + 2);
    return dpair.map((playerNumber) =>
      this.game.getPlayerObj(playerNumber, team)
    );
  }

  private getForwards(line: number, team: string): Player[] {
    const offense = this.game.getForwards(team);
    const fIndex = (line - 1) * 3;
    const forwards = offense.slice(fIndex, fIndex + 3);
    return forwards.map((playerNumber) =>
      this.game.getPlayerObj(playerNumber, team)
    );
  }

  get homeWarnings(): string[] {
    if (!this.strengthState) {
      return [];
    }
    let numFieldPlayersRequired = parseInt(
      this.strengthState.split('-')[0],
      10
    );
    if (this.isHomeTeamEmptyNet) {
      numFieldPlayersRequired += 1;
    }

    const numFieldPlayersOnIce = this.onIce.filter(
      (s) =>
        s.player &&
        s.player.team === this.game.homeTeam &&
        s.player.position !== 'goalkeeper'
    ).length;
    const goalKeeperOnIce = this.onIce.filter(
      (s) =>
        s.player &&
        s.player.team === this.game.homeTeam &&
        s.player.position === 'goalkeeper'
    ).length;

    return this.getWarnings(
      numFieldPlayersOnIce,
      numFieldPlayersRequired,
      this.isHomeTeamEmptyNet,
      goalKeeperOnIce
    );
  }

  get awayWarnings(): string[] {
    if (!this.strengthState) {
      return [];
    }
    let numFieldPlayersRequired = parseInt(
      this.strengthState.split('-')[1],
      10
    );
    if (this.isAwayTeamEmptyNet) {
      numFieldPlayersRequired += 1;
    }

    const numFieldPlayersOnIce = this.onIce.filter(
      (s) =>
        s.player &&
        s.player.team === this.game.awayTeam &&
        s.player.position !== 'goalkeeper'
    ).length;
    const goalKeeperOnIce = this.onIce.filter(
      (s) =>
        s.player &&
        s.player.team === this.game.awayTeam &&
        s.player.position === 'goalkeeper'
    ).length;

    return this.getWarnings(
      numFieldPlayersOnIce,
      numFieldPlayersRequired,
      this.isAwayTeamEmptyNet,
      goalKeeperOnIce
    );
  }

  private getWarnings(
    numFieldPlayersOnIce,
    numFieldPlayersRequired,
    emptyNet,
    goalKeeperOnIce
  ): string[] {
    const warnings = [];
    if (numFieldPlayersOnIce < numFieldPlayersRequired) {
      warnings.push(
        `Too few field players on ice. Required are ${numFieldPlayersRequired} field players.`
      );
    } else if (numFieldPlayersOnIce > numFieldPlayersRequired) {
      warnings.push(
        `Too many field players on ice. Required are ${numFieldPlayersRequired} field players.`
      );
    }

    if (!emptyNet && !goalKeeperOnIce) {
      warnings.push('Goalkeeper required to be on ice.');
    } else if (emptyNet && goalKeeperOnIce) {
      warnings.push('Goalkeeper not allowed to be on ice with empty net.');
    }

    return warnings;
  }

  updateActiveShifts(shifts: Shift[], gameTime: number, period: string) {
    this.onIce = shifts.filter(
      (s) =>
        s.onEvent &&
        s.onEvent.period === period &&
        s.onEvent.gameTime <= gameTime &&
        (!s.offEvent || gameTime < s.offEvent.gameTime)
    );
    this.activeShifts = {};
    this.onIce.forEach((s) => {
      if (s.player) {
        this.activeShifts[s.player.playerNumber] = s;
      }
    });
  }

  changeTab($event: MatTabChangeEvent) {
    this.eventsOverviewComponent.team =
      $event.index === 0 ? this.game.homeTeam : this.game.awayTeam;
  }
}
