import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Player, PlayerPosition } from '../domain/player';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import {
  startWith,
  map,
  switchMap,
  debounceTime,
  filter,
  share
} from 'rxjs/operators';
import { PlayerService } from '../services/player.service';
import { toLower, trim, filter as _filter, isObject, first } from 'lodash/fp';

@Component({
  selector: 'app-all-players-input',
  templateUrl: './all-players-input.component.html',
  styleUrls: ['./all-players-input.component.scss']
})
export class AllPlayersInputComponent implements OnInit {
  @Input() idType: string = 'id';
  @Input() teamName: string;
  @Input() position: PlayerPosition;
  @Input() positionName: string;
  @Input() positionKey: string;

  player: Player;

  playerName$ = new BehaviorSubject<string | null>(null);

  @Input('player') set playerName(p: string) {
    this.playerName$.next(p);
    this.playerFormControl.setValue(p, { emitEvent: false });
  }

  playerId$ = new BehaviorSubject<string | null>(null);

  @Input() set playerId(id: string) {
    this.playerId$.next(id);
  }

  playerPosition$: Observable<string> = of(undefined);

  playerFormControl = new UntypedFormControl(null, [Validators.required]);

  filteredPlayers$: Observable<Player[]>;
  positionPlayers$: Observable<Player[]>;

  players$ = new BehaviorSubject<Player[]>([]);

  @Input() set players(px: Player[]) {
    this.players$.next(px);
  }

  @Output() playerChange = new EventEmitter<{
    player: Player | null;
    positionKey: string;
  }>();

  isTeamPlayer$: Observable<boolean>;

  constructor(private playerService: PlayerService) {}

  selectionChange(player: Player) {
    this.playerFormControl.setValue(this.displayPlayerFn(player), {
      emitEvent: false
    });
    this.player = player;
    this.playerChange.emit({ player, positionKey: this.positionKey });
  }

  ngOnInit() {
    this.positionPlayers$ = this.players$.pipe(
      map(_filter((p) => p.position === this.position))
    );

    this.playerPosition$ = combineLatest([
      this.playerId$,
      this.playerName$.pipe(map((v) => v && v.replace(/\d|-/g, '').trim())),
      this.players$
    ]).pipe(
      switchMap(([id, playerName, players]) => {
        if (id && !playerName) {
          return of('missing');
        }
        if (!id || !playerName || !players) {
          return of(undefined);
        }
        const foundPlayer = players.find((p) => p.id === id);
        if (foundPlayer) {
          return of(
            foundPlayer.position === this.position ? 'default' : 'non-default'
          );
        }
        if (this.player?.id === id) {
          return of(
            this.player.position === this.position ? 'default' : 'non-default'
          );
        }
        return this.playerService
          .searchPlayersByName(playerName, this.idType, {
            position: this.position
          })
          .pipe(
            map((foundPlayers) => {
              const found = foundPlayers.find((p) => p.id === id);
              if (!found) {
                return 'missing';
              }
              this.player = found;
              return found.position === this.position
                ? 'default'
                : 'non-default';
            })
          );
      }),
      share()
    );

    this.filteredPlayers$ = this.playerFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(1000),
      filter((value) => isObject(value) === false),
      map(toLower),
      map(trim),
      switchMap((value: string) => {
        if (value.length > 0) {
          return this.playerService.searchPlayersByName(value, this.idType, {
            position: this.position
          });
        }
        return this.positionPlayers$;
      })
    );

    this.isTeamPlayer$ = combineLatest([this.playerId$, this.players$]).pipe(
      debounceTime(1000),
      map(([id, players]) => players?.some((p) => p.id === id))
    );
  }

  clear() {
    this.player = null;
    this.playerFormControl.setValue(null);
    this.playerChange.emit({ player: null, positionKey: this.positionKey });
  }

  displayPlayerFn(player: Player | string) {
    if (!player) {
      return null;
    }
    if (typeof player === 'string') {
      return player;
    }
    const jerseyNumber = player.jerseyNumber ? `${player.jerseyNumber} - ` : '';
    return jerseyNumber.concat(`${player.lastName} ${player.firstName}`);
  }

  isClassWarning(playerId, playerPosition, isTeamPlayer) {
    return (
      playerPosition === 'non-default' || (playerId && isTeamPlayer === false)
    );
  }

  isClassDanger(playerId, playerPosition, isTeamPlayer) {
    if (this.playerFormControl.value === '') {
      return false;
    }
    return (
      (isTeamPlayer !== null &&
        !isTeamPlayer &&
        playerPosition === 'missing') ||
      (isTeamPlayer !== null && playerId === '')
    );
  }

  isShowPlayerMissingInRegister(playerPosition, playerName) {
    if (this.playerFormControl.value === '') {
      return false;
    }
    return (
      playerPosition === 'missing' ||
      ((playerPosition === null || playerPosition === undefined) &&
        playerName === '')
    );
  }
}
