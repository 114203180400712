import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ExportStatus,
  GameEvent,
  HighlightRating,
  VideoClip,
  VideoReviewType
} from '../../domain/game-event';

@Component({
  selector: 'app-game-event-highlight',
  templateUrl: './game-event-highlight.component.html',
  styleUrls: [
    '../game-events.component.css',
    './game-event-highlight.component.css'
  ]
})
export class GameEventHighlightComponent {
  @Input() event!: GameEvent;
  @Input() isExportVideoReviewClips!: boolean;

  @Output() markVideoReviewClicked: EventEmitter<VideoClip> =
    new EventEmitter();
  @Output() exportVideoReviewClicked: EventEmitter<GameEvent> =
    new EventEmitter();
  @Output() eventChange = new EventEmitter<GameEvent>();

  readonly videoReviewTypes: Record<VideoReviewType, string> = {
    videoReview: 'Video Review',
    coachesChallenge: 'Coaches Challenge'
  };

  readonly videoReviewTypeKeys = Object.keys(this.videoReviewTypes);

  constructor() {}

  canExportClip(event: GameEvent): boolean {
    if (!event.videoReviewClip) {
      return false;
    }

    const isMarked =
      event.videoReviewClip?.startVideoTime &&
      event.videoReviewClip?.endVideoTime;
    if (!isMarked) {
      return false;
    }
    const pendingTimeoutAt = (event.videoReviewClip?.exportStatus as any)
      ?.timeoutAt;
    return (
      !pendingTimeoutAt || new Date(pendingTimeoutAt).getTime() < Date.now()
    );
  }

  exportStatus(status: ExportStatus) {
    switch (status.status) {
      case 'not_started':
        return 'Not Exported';

      case 'pending':
        const pendingTimeoutAt = new Date(status.timeoutAt).getTime();
        return pendingTimeoutAt < Date.now() ? 'Timed out' : 'Pending';

      case 'transcoding':
        return 'Transcoding';

      case 'complete':
        return 'Complete';

      case 'failed':
        return 'Failed';

      default:
        return 'Unknown';
    }
  }

  vimeoUrl(vimeoVideoId: string) {
    return `https://player.vimeo.com/video/${vimeoVideoId}`;
  }

  unmarkVideoReview() {
    console.log('unmarked video review', this.event);
    this.event.videoReviewClip = null;
    this.event.videoReviewType = null;
  }

  exportVideoReview(event: GameEvent) {
    this.exportVideoReviewClicked.emit(event);
  }

  markVideoReview(videoReviewClip: VideoClip) {
    this.markVideoReviewClicked.emit(videoReviewClip);
  }

  ratingChange(rating: HighlightRating) {
    this.event.highlightRating = rating;
  }

  resetHighlightData() {
    this.event.highlightPlayback = null;
    this.event.highlightRating = null;
  }
}
