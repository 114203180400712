import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GameEvent, OfficialsCallSituation } from '../../../domain/game-event';

@Component({
  selector: 'app-officiating-call-situation',
  templateUrl: './officiating-call-situation.component.html',
  styleUrls: ['./officiating-call-situation.component.css']
})
export class OfficiatingCallSituationComponent {
  @Input()
  event: GameEvent;

  @Output()
  selectionChange = new EventEmitter<void>();

  readonly allOfficialsCallSituations: {
    key: OfficialsCallSituation;
    description: string;
  }[] = [
    {
      key: 'clear',
      description: 'Clear'
    },
    {
      key: 'close',
      description: 'Close'
    }
  ];
}
