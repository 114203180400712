<div class="form-group row">
  <label class="col-sm-2 col-form-label">Highlight Type</label>
  <div class="col-sm-10">
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-1"
    >
      <input
        type="radio"
        id="ht-1"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="bench"
      />
      <span class="mdl-radio__label">Bench</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-2"
    >
      <input
        type="radio"
        id="ht-2"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="blooper"
      />
      <span class="mdl-radio__label">Blooper</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-3"
    >
      <input
        type="radio"
        id="ht-3"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="close_up"
      />
      <span class="mdl-radio__label">Close Up</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-4"
    >
      <input
        type="radio"
        id="ht-4"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="compilation"
      />
      <span class="mdl-radio__label">Compilation</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-5"
    >
      <input
        type="radio"
        id="ht-5"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="goal"
      />
      <span class="mdl-radio__label">Goal</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-6"
    >
      <input
        type="radio"
        id="ht-6"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="highlight_play"
      />
      <span class="mdl-radio__label">Highlight Play</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-7"
    >
      <input
        type="radio"
        id="ht-7"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="hit"
      />
      <span class="mdl-radio__label">Hit</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-8"
    >
      <input
        type="radio"
        id="ht-8"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="penalty_box"
      />
      <span class="mdl-radio__label">Penalty Box</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-9"
    >
      <input
        type="radio"
        id="ht-9"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="save"
      />
      <span class="mdl-radio__label">Save</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-10"
    >
      <input
        type="radio"
        id="ht-10"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="shot"
      />
      <span class="mdl-radio__label">Shot</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-11"
    >
      <input
        type="radio"
        id="ht-11"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="stands"
      />
      <span class="mdl-radio__label">Stands</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-12"
    >
      <input
        type="radio"
        id="ht-12"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="titles"
      />
      <span class="mdl-radio__label">Titles</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-13"
    >
      <input
        type="radio"
        id="ht-13"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="penalty_shot"
      />
      <span class="mdl-radio__label">Penalty Shot</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ht-14"
    >
      <input
        type="radio"
        id="ht-14"
        class="mdl-radio__button"
        name="ht-options"
        [(ngModel)]="event.highlightType"
        (ngModelChange)="resetHighlightData()"
        value="video_review"
      />
      <span class="mdl-radio__label">Video Review</span>
    </label>
  </div>
  <label
    class="col-sm-2 col-form-label"
    *ngIf="
      event.highlightType &&
      !(
        event.highlightType === 'goal' ||
        event.highlightType === 'save' ||
        event.highlightType === 'penalty_shot' ||
        event.highlightType === 'video_review'
      )
    "
    >Playback</label
  >
  <div
    class="col-sm-10"
    *ngIf="
      event.highlightType &&
      !(
        event.highlightType === 'goal' ||
        event.highlightType === 'save' ||
        event.highlightType === 'penalty_shot' ||
        event.highlightType === 'video_review'
      )
    "
  >
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="htp-1"
    >
      <input
        type="radio"
        id="htp-1"
        class="mdl-radio__button"
        name="htp-options"
        [(ngModel)]="event.highlightPlayback"
        value="normal"
      />
      <span class="mdl-radio__label">Normal</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="htp-2"
    >
      <input
        type="radio"
        id="htp-2"
        class="mdl-radio__button"
        name="htp-options"
        [(ngModel)]="event.highlightPlayback"
        value="slow_motion"
      />
      <span class="mdl-radio__label">Slow Motion</span>
    </label>
  </div>
  <label
    *ngIf="
      event.highlightType &&
      event.highlightType !== 'titles' &&
      event.highlightType !== 'video_review'
    "
    class="col-sm-2 col-form-label"
    >Rating</label
  >
  <div
    *ngIf="
      event.highlightType &&
      event.highlightType !== 'titles' &&
      event.highlightType !== 'video_review'
    "
    class="col-sm-4"
  >
    <app-star-rating
      [starCount]="5"
      [color]="'primary'"
      [ngModel]="event.highlightRating"
      (ngModelChange)="ratingChange($event)"
    ></app-star-rating>
  </div>

  <ng-container
    *ngIf="isExportVideoReviewClips && event.highlightType === 'video_review'"
  >
    <label class="col-sm-2 col-form-label">Video review</label>
    <div class="col-sm-4 ing-video-review-clip-status-container">
      <div class="ing-mark-col">
        <div
          *ngIf="
            !(
              event.videoReviewClip &&
              event.videoReviewClip.startVideoTime &&
              event.videoReviewClip.endVideoTime
            )
          "
        >
          <h6 class="ing-status-title">
            <mat-icon
              title="Missing video review time"
              matTooltip="Not marked"
              *ngIf="
                !(
                  event.videoReviewClip &&
                  event.videoReviewClip.startVideoTime &&
                  event.videoReviewClip.endVideoTime
                )
              "
              class="video-review-clip-icon"
              >warning</mat-icon
            >
            Not marked
          </h6>
        </div>
        <div
          *ngIf="
            event.videoReviewClip &&
            event.videoReviewClip.startVideoTime &&
            event.videoReviewClip.endVideoTime
          "
        >
          <h6 class="ing-status-title">
            <mat-icon
              title="Video review complete"
              matTooltip="Video review marked"
              class="video-review-clip-icon"
              >done</mat-icon
            >
            Marked
          </h6>
        </div>
        <button
          mat-button
          color="primary"
          (click)="markVideoReview(event?.videoReviewClip)"
          >Mark Video Review</button
        >
        <button
          *ngIf="event.videoReviewClip?.startVideoTime"
          mat-button
          color="warn"
          (click)="unmarkVideoReview()"
          >Unmark Video Review</button
        >
      </div>

      <div class="ing-upload-status-col" *ngIf="event?.videoReviewClip">
        <h6
          *ngIf="event?.videoReviewClip?.exportStatus?.status"
          matTooltip="Export to Vimeo"
          class="ing-status-title"
          >{{ exportStatus(event?.videoReviewClip?.exportStatus) }}
        </h6>
        <button
          [disabled]="canExportClip(event) === false"
          (click)="exportVideoReview(event)"
          mat-button
        >
          <mat-icon style="margin-right: 5px">cloud_upload</mat-icon>
          <span
            *ngIf="canExportClip(event) && event.videoReviewClip.vimeoVideoId"
            >Re-</span
          >
          Export
        </button>
        <a
          mat-button
          *ngIf="event?.videoReviewClip?.vimeoVideoId"
          [href]="vimeoUrl(event?.videoReviewClip?.vimeoVideoId)"
          target="_blank"
        >
          <mat-icon>link</mat-icon>
          Vimeo
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="isExportVideoReviewClips && event.highlightType === 'video_review'"
  >
    <label class="col-sm-2 col-form-label">Type</label>
    <div class="col-sm-4">
      <label
        *ngFor="let videoReviewType of videoReviewTypeKeys"
        class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
        [for]="videoReviewType"
      >
        <input
          type="radio"
          [id]="videoReviewType"
          class="mdl-radio__button"
          name="video-review-options"
          [(ngModel)]="event.videoReviewType"
          (ngModelChange)="resetHighlightData()"
          [value]="videoReviewType"
        />
        <span class="mdl-radio__label">{{
          videoReviewTypes[videoReviewType]
        }}</span>
      </label>
    </div>
  </ng-container>
</div>
