import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GameEvent, GameIncidentType } from '../../../domain/game-event';

@Component({
  selector: 'app-game-incident-type',
  templateUrl: './game-incident-type.component.html',
  styleUrls: ['./game-incident-type.component.css']
})
export class GameIncidentTypeComponent {
  @Input()
  event: GameEvent;

  @Output()
  selectionChange = new EventEmitter<void>();

  readonly allGameIncidentTypes: {
    key: GameIncidentType;
    description: string;
  }[] = [
    { key: 'penalty', description: 'Penalty' },
    { key: 'icing', description: 'Icing' },
    { key: 'offside', description: 'Offside' }
  ];
}
