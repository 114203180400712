import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GameEvent, OfficialsCallAssessment } from '../../../domain/game-event';

@Component({
  selector: 'app-officiating-call-assessment',
  templateUrl: './officiating-call-assessment.component.html',
  styleUrls: ['./officiating-call-assessment.component.css']
})
export class OfficiatingCallAssessmentComponent {
  @Input()
  event: GameEvent;

  @Output()
  selectionChange = new EventEmitter<void>();

  allOfficialsCallAssessments: {
    key: OfficialsCallAssessment;
    description: string;
  }[] = [
    { key: 'unspecified', description: 'Unspecified' },
    { key: 'wrong', description: 'Wrong' },
    { key: 'correct', description: 'Correct' }
  ];
}
