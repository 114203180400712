<mat-form-field>
  <mat-select
    [(value)]="event.officialsCallAction"
    (selectionChange)="selectionChange.emit($event)"
  >
    <mat-option [value]="null"></mat-option>
    <mat-option
      *ngFor="let action of allOfficialsCallActions"
      [value]="action.key"
    >
      {{ action.description }}
    </mat-option>
  </mat-select>
</mat-form-field>
