import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, from as observableFrom, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Team } from '../domain/team';
import { AlertService } from './alert.service';

@Injectable()
export class TeamService {
  private baseUrl = environment.API_HOST + '/api/teams';

  constructor(private http: HttpClient, private alertService: AlertService) {}

  findTeamsByName(idType: string, query: string) {
    return this.http.get<Team[]>(`${this.baseUrl}/search`, {
      params: new HttpParams().set('q', query).set('idType', idType)
    });
  }

  async getTeams(leagueId: string, season: string, idType: string) {
    if (!leagueId) {
      this.alertService.showError(`Could not set teams: 'League' is undefined`);
      return [];
    }
    if (!season) {
      this.alertService.showError(`Could not set teams: 'Season' is undefined`);
      return [];
    }
    if (!idType) {
      this.alertService.showError(`Could not set teams: 'IdType' is undefined`);
      return [];
    }
    return firstValueFrom(
      this.http.get<Team[]>(
        `${this.baseUrl}/${leagueId}?season=${season}&idType=${idType}`
      )
    );
  }

  createOrUpdateTeam(team): Observable<any[]> {
    if (team.id) {
      return this.http.put<any[]>(`${this.baseUrl}/${team.id}`, team);
    }
    return this.http.post<any[]>(this.baseUrl, team);
  }

  deleteTeam(teamId): Observable<any[]> {
    return this.http.delete<any[]>(`${this.baseUrl}/${teamId}`);
  }

  updateTeamExternalIDs(
    teamId: string,
    sihfId: string,
    eliteProspectsId: string,
    sportcontractId: string,
    nhlId: string,
    instatId: string
  ) {
    if (!teamId) {
      return observableFrom([]);
    }
    return this.http.post(`${this.baseUrl}/external-ids`, {
      teamId,
      sihfId,
      eliteProspectsId,
      sportcontractId,
      nhlId,
      instatId
    });
  }

  getFieldNameByIdType(idType) {
    let field = 'id'; // default 'Master'
    if (idType === 'SIHF') {
      field = 'sihfId';
    }
    if (idType === 'EliteProspects') {
      field = 'eliteProspectsId';
    }
    if (idType === 'InStat') {
      field = 'instatId';
    }
    if (idType === 'NHL') {
      field = 'nhlId';
    }
    if (idType === 'None') {
      field = '';
    }
    return field;
  }
}
